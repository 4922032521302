<template>
  <div>
    <Msg :labelValues="labelValues" />
    <footer-tool v-if="showFooter" :objectInfo="trackInfo" />
  </div>
</template>
<script>
import get from 'lodash/get'
import Msg from '../../../Msg'
import FooterTool from '../../../footerTool'
export default {
  name: 'MapTraditionalVehicleTrackInfoWindow',
  components: { Msg, FooterTool },
  props: {
    // 对象信息
    objectInfo: {
      type: Object,
      default: () => {}
    },
    // 轨迹信息
    trackInfo: {
      type: Object,
      default: () => {}
    },
    currentMapAddress: {
      type: Object,
      default: () => {}
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.buildData()
  },
  methods: {
    buildData() {
      this.labelValues = []
      const address = get(this.trackInfo, 'address', '')
      if (address) {
        this.labelValues.push({
          label: '所在位置',
          value: address
        })
      }
      this.labelValues.push({
        label: '车辆自编号',
        value: get(this.objectInfo, 'bindKey', '')
      })
      this.labelValues.push({
        label: '车牌号',
        value: get(this.objectInfo, 'bindName', '')
      })
      // this.labelValues.push({
      //   label: '责任人',
      //   value: get(this.objectInfo, 'jobNumber', '')
      // })
      const speed = get(this.trackInfo, 'speed', '')
      this.labelValues.push({
        label: '速度',
        value: speed ? speed + 'km/h' : '暂无速度'
      })
      this.labelValues.push({
        label: '定位时间',
        value: get(this.trackInfo, 'deviceTime', '')
      })
      // this.labelValues.push({
      //   label: '设备电量',
      //   value: get(this.objectInfo, 'electQuantity', '')
      // })
      // const pValue = get(this.objectInfo, 'powerValue', '')
      // this.labelValues.push({
      //   label: '电源',
      //   value: pValue ? '已接通' : '未接通'
      // })
    }
  },
  watch: {
    objectInfo(newVal, oldVal) {},
    trackInfo(newVal, oldVal) {
      this.buildData()
    }
  },
  data() {
    return {
      labelValues: []
    }
  }
}
</script>
